@import "~antd/dist/antd.css";
@import "~react-image-gallery/styles/css/image-gallery.css";
@import "https://use.fontawesome.com/releases/v5.2.0/css/all.css";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@700&display=swap");

:root {
  --font-primary: "Inter", sans-serif;
  --font-secondary: "Dosis", sans-serif;
  --cold_grey: #f9f9fb;
  --primary-color: #dd9b27;
  --text-color: #000000;
  --error: #e74c3c;
  --dark-blue: #001d3a;
  --brand-violet2: #726291;
  --brand-silver: #bdc3c7;
  --brand-red-gradient: linear-gradient(
    90deg,
    rgba(215, 0, 0, 1) 0%,
    rgba(192, 0, 0, 1) 30%,
    rgba(238, 0, 0, 1) 100%
  );
  --brand-red: #ea2b0f;
  --brand-red-background: #fff1ee;
  --brand-dark-grey: #212121;
  --brand-blue: #2bbef9;
  --brand-blue-background: #f0faff;
}
body {
  background-color: #ffffff;
}

h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h5.ant-typography,
h6.ant-typography {
  color: var(--text-color);
}

::-moz-selection {
  color: #fff;
  background: var(--brand-silver) !important;
}
::selection {
  color: #fff;
  background: var(--brand-silver) !important;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: rgba(255, 255, 255, 0.2);
}
.ant-menu-horizontal > .ant-menu-item > a:hover {
  color: var(--cold_grey);
}
.ant-menu-horizontal > .ant-menu-item:hover {
  border: none;
  border-bottom: none;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a {
  color: rgba(255, 255, 255, 0.85);
}

a {
  color: var(--text-color);
  font-weight: 500;
  font-family: var(--font-secondary);
}

span {
  font-family: var(--font-secondary);
}
.ant-spin-nested-loading {
  width: 100%;
}
.ant-menu-horizontal > .ant-menu-item-selected a {
  color: var(--brand-blue);
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
  border-bottom: none;
}

.ant-menu-horizontal > .ant-menu-item a:hover {
  color: var(--brand-blue);
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after {
  border-bottom: none;
}

a:hover {
  color: var(--brand-blue);
}

.sider-menu {
  position: absolute;
  left: 0;
  top: 80px;
  z-index: 99;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
  margin-right: 6px;
}
.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu-title {
  transition: background-color 400ms ease-in-out;
  border-radius: 2.5rem;
}
.ant-menu-title-content {
  transition: background-color 400ms ease-in-out;
  border-radius: 2.5rem;
}
.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  transition: background-color 400ms ease-in-out;
  border-radius: 2.5rem;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected {
  background-color: var(--brand-blue-background);
  font-weight: 500;
  letter-spacing: 0;
  color: var(--brand-blue);
  border-radius: 2.5rem;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
  background-color: var(--brand-blue-background);
  font-weight: 500;
  letter-spacing: 0;
  color: var(--brand-blue);
  border-radius: 2.5rem;
}
.ant-divider-horizontal {
  margin: 12px 0;
}
.row-type {
  font-size: 24px;
  transition: 0.3s;
}

.row-type:hover {
  color: var(--brand-blue);
}

.ant-progress-line {
  position: absolute;
  max-width: 1200px;
  font-size: 14px;
  margin: auto;
  top: 50%;
  z-index: 9999;
}
.ant-menu-vertical .ant-menu-item > a {
  color: #ffffff;
  white-space: normal;
  width: 100%;
  font-size: 16px;
}
.ant-menu-vertical .ant-menu-item > a:hover {
  color: var(--brand-silver);
}

.weight-or-volume {
  width: 100%;
  border: 1px solid #f0f0f0;
  margin-bottom: 12px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02),
    0 1px 2px rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02), 0 1px 2px rgba(0, 0, 0, 0.08);
}
.weight-or-volume p {
  background: var(--brand-blue);
  color: #ffffff;
  padding: 6px;
  font-weight: 700;
  font-size: 18px;
}
.weight-or-volume div {
  padding: 6px;
}

.selected-products-header {
  background: var(--brand-blue-background);
  padding: 12px;
  color: var(--brand-blue);
  border-radius: 6px;
}
.selected-products-header h3.ant-typography {
  color: var(--brand-blue);
  font-family: var(--font-secondary);
  text-transform: uppercase;
  margin: 0;
}

/* effect #2 */
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
.ant-menu-vertical .ant-menu-item {
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 18px 36px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  text-decoration: none;
  overflow: hidden;
}
.ant-menu-vertical .ant-menu-item:before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  display: block;
  width: 200%;
  height: 200%;
  content: "";
  -webkit-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
  -webkit-transform-origin: center top;
  transform-origin: center top;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  z-index: -1;
}
.ant-menu-vertical .ant-menu-item:after {
  position: absolute;
  top: 1px;
  right: 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
  width: 40px;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-size: 25px;
  font-weight: 900;
  line-height: inherit;
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  opacity: 0;
}
.ant-menu-vertical .ant-menu-item:hover {
  padding-left: 26px;
  padding-right: 46px;
}
.ant-menu-vertical .ant-menu-item:hover:before {
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
}
.ant-menu-vertical .ant-menu-item:hover:after {
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.ant-menu-vertical.ant-menu-sub {
  min-width: 200px;
}
.ant-btn-link {
  color: var(--error);
}
#root {
  background: #ffffff;
}

#route {
  background: #ffffff;
  margin: auto;
  min-height: 100vh;
}
.logo {
  margin: auto 16px 16px 0;
  text-align: left;
}

.logo img {
  width: 160px;
}

.ant-btn-primary {
  background-color: var(--brand-blue);
  border-color: var(--brand-blue);
  border-radius: 4px;
}

.ant-btn:hover,
.ant-btn:focus {
  border-color: var(--brand-blue);
  color: var(--brand-blue);
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: var(--cold_grey);
}

.ant-input-number:hover {
  border-color: var(--brand-silver);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--brand-blue);
  border-color: var(--brand-blue);
}

.ant-checkbox-checked::after {
  border: 1px solid var(--brand-blue);
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--brand-blue);
}

.ant-layout-content {
  max-width: 1170px;
  margin: auto;
  padding: 6px;
}

.ant-layout-header {
  height: auto;
  padding: 0;
  line-height: 24px;
  background: #ffffff;
  margin: 6px auto 0 auto;
  border-bottom: 1px solid #e3e4e6;
}

.ant-menu.ant-menu-dark {
  background: var(--brand-blue);
}

.ant-menu-horizontal {
  padding: 0 20px 0 0;
  border-radius: 4px;
  box-shadow: none;
}

.ant-menu-vertical .ant-menu-submenu-title {
  padding: 0px 36px;
  color: rgba(0, 0, 0, 0.85);
}

.ant-menu-vertical .ant-menu-submenu-title span a {
  color: rgba(0, 0, 0, 0.85);
}

.ota-search input.ant-input {
  background-color: #f3f4f7;
  color: #202435;
  border-color: #f3f4f7;
  align-items: center;
  font-family: var(--font-primary);
  font-size: 0.9375rem;
  width: 100%;
  padding: 0 0.9375rem;
  box-shadow: none;
  outline: none;
  appearance: none;
  border: 1px solid transparent;
  transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  height: 3.75rem;
}
.ota-search .ant-input-group-addon {
  background-color: #f3f4f7;
}
.ant-input-search-button {
  border-color: none;
  background-color: #f3f4f7;
  border: none;
  box-shadow: none;
}
.ant-input-search-button:hover {
  border-color: none;
  background-color: #f3f4f7;
  border: none;
  box-shadow: none;
}
.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button:not(.ant-btn-primary) {
  color: #202435;
}
.ant-menu-vertical {
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02),
    0 1px 2px rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02), 0 1px 2px rgba(0, 0, 0, 0.08);
}
.ant-menu-vertical li:not(:last-child) {
  border-bottom: 1px solid var(--brand-blue-background);
}
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 30px;
  line-height: 30px;
}

.ant-layout-sider {
  background: var(--cold_grey);
}

.ant-layout {
  background: #ffffff;
}

.ant-layout-sider-children {
  height: auto;
  border-radius: 4px;
  background: #ffffff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02),
    0 1px 2px rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02), 0 1px 2px rgba(0, 0, 0, 0.08);
}

.ant-menu-inline {
  border-right: 1px solid #e8e8e8;
  border-radius: 4px;
}

.ant-menu-horizontal {
  border: none;
}

.headerMenu {
  max-width: 100%;
  margin: auto;
}

.mobile-categories {
  color: #ffffff;
  font-weight: 700;
  display: inline-flex;
  margin-left: 12px;
  font-family: var(--font-secondary);
  text-transform: uppercase;
}
.mobile-categories:hover {
  cursor: pointer;
}

.mobile-menu {
  position: absolute;
  z-index: 99;
  border: 1px solid var(--brand-blue);
  width: 100%;
  margin: auto;

}
.mobile-menu.ant-menu-root.ant-menu-vertical {
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02),
    0 1px 2px rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02), 0 1px 2px rgba(0, 0, 0, 0.08);
}
/* For demo */

.first-slide div {
  position: absolute;
}

.first-slide a > img {
  object-fit: contain;
  width: 100%;
}

.first-slide {
  background-size: cover;
  background-repeat: no-repeat;
}

.secondSlide {
  background-size: cover;
  background-repeat: no-repeat;
}

.thirdSlide {
  background-size: cover;
  background-repeat: no-repeat;
}

.footer-logo img {
  max-width: 200px;
}
.after-logo {
  margin-top: 4px;
}

.catLogo {
  width: 140px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px auto;
  text-align: center;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: var(--brand-silver);
}
.ant-menu-item-selected {
  color: var(--brand-silver);
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: var(--brand-silver);
  border-bottom: none;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid var(--brand-silver);
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: var(--brand-silver);
}
span:hover {
  border-color: var(--brand-silver) !important;
}
.calculator.ant-card {
  background: var(--brand-blue);
  color: var(--cold_grey);
  padding: 12px;
}
.calculator .ant-form-item-label > label {
  color: var(--cold_grey);
}
.calculator .ant-card-body {
  display: block;
}

.products-grids .ant-btn {
  padding: 4px 8px;
}
.ant-input {
  border-radius: 4px;
  border: 1px solid var(--brand-blue);
}
.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 4px;
}
.ant-input-number-lg {
  border-radius: 4px;
}
.ant-input-number-input {
  border-radius: 4px;
}
.ant-input-number-handler-wrap {
  border-radius: 0 12px 12px 0;
}
.ant-input:focus {
  border-color: var(--brand-silver);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ant-input:hover {
  border-color: var(--brand-silver);
  border-right-width: 1px !important;
}
.ant-card {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: var(--text-color);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum", "tnum";
  position: relative;
  background: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08),
    0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08), 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  -o-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.ant-card:hover {
  -webkit-box-shadow: 0 7px 14px rgba(0, 0, 0, 0.12),
    0 5px 5px rgba(0, 0, 0, 0.09);
  box-shadow: 0 7px 14px rgba(0, 0, 0, 0.12), 0 5px 5px rgba(0, 0, 0, 0.09);
}

.ant-card-head {
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 24px;
  color: var(--text-color);
  font-weight: 700;
  font-size: 18px;
  background: transparent;
  border-bottom: 1px solid var(--cold_grey);
  border-radius: 2px 2px 0 0;
  zoom: 1;
}
.ant-card-head-title {
  display: inline-block;
  -ms-flex: 1;
  -webkit-box-flex: 1;
  flex: 1 1;
  padding: 12px 0;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.ant-pagination-item-active a {
  color: var(--brand-silver);
}
.ant-pagination-item-active {
  border-color: var(--brand-silver);
}
.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
  border-color: var(--brand-silver);
}
.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: var(--brand-silver);
}
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: var(--brand-silver);
}
.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: var(--brand-silver);
}
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: var(--brand-silver);
  border-color: var(--brand-silver);
}

.archive-card .ant-card-meta-detail {
  display: block;
  position: relative;
  bottom: 20px;
}
.archive-card .ant-card-meta-title {
  font-size: 24px;
  background: none;
}
.archive-card .ant-card-meta-description {
  padding: 0 12px;
}
.archive-card .ant-card-cover {
  margin: 12px;
}
.archive-card .ant-card-meta {
  width: 100%;
}

.archive-card .ant-card-cover img {
  border-radius: 24px;
  box-shadow: 0px 10px 25px -6px rgba(54, 32, 99, 0.41);
  margin-bottom: 24px;
  z-index: 20;
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  -o-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  height: 200px;
  object-fit: cover;
}

.archive-card .ant-card-cover > * {
  padding: 0;
  margin: -12px 0;
}
.archive-card .ant-card-actions > li {
  margin: auto 0;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
}
.archive-card .ant-card-actions > li > span {
  line-height: 40px;
}
.archive-card .ant-card-actions > li > span p {
  margin: 0;
}
.archive-card .ant-card-actions > li:last-child > span {
  text-align: left;
}

.categories-cards .ant-card-body {
  padding: 0;
}
.categories-cards.ant-card {
  box-shadow: none;
}
.categories-cards.ant-card:hover {
  box-shadow: none;
}
.footer {
  padding: 0 16px 0 16px;
  background: #bababa;
  color: #ffffff;
}
.footer a {
  color: #ffffff;
}
.footer a:hover {
  color: var(--brand-blue);
}
.bottom-bar {
  text-align: center;
  color: white;
  font-weight: 600;
  margin: 0 auto 4px auto;
}
.footer .ant-col {
  padding: 16px 0;
  text-align: center;
}
.footer-menu {
  font-size: 20px;
  text-transform: capitalize;
  list-style: none;
}

.footer-menu svg {
  margin-right: 6px;
}
.footer-menu:hover {
  color: var(--brand-blue);
}
.find-us {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 5px;
  justify-content: center;
}
.find-us p {
  font-size: 16px;
  font-weight: 500;
  margin: auto 0;
}
.footer-phones {
  text-align: center;
  display: -ms-grid;
  align-items: center;
  display: grid;
}
.footer-phones a {
  display: block;
}
.payment-icons img {
  width: 60px;
  filter: invert(1);
  display: block;
  opacity: 0.6;
}

.footer .social-icons svg {
  font-size: 36px;
  margin-right: 12px;
  color: white;
  max-width: 30px;
}
.footer .social-icons svg:hover {
  color: var(--brand-red);
}

.fade-enter {
  opacity: 0;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.fade-appear {
  opacity: 0;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.fade-leave {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.fade-enter.fade-enter-active {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.fade-appear.fade-appear-active {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.fade-leave.fade-leave-active {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.bannerOne {
  height: 250px;
  line-height: 250px;
  background-size: cover;
  background-repeat: no-repeat;
  background: transparent;
}
.bannerTwo {
  height: 250px;
  line-height: 250px;
  background-size: cover;
  background-repeat: no-repeat;
}

.leftTowerBanner,
.rightTowerBanner,
.bottomBanner {
  position: relative;
  margin: 20px 0;
  border-radius: 4px;
  width: 100%;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02),
    0 1px 2px rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02), 0 1px 2px rgba(0, 0, 0, 0.08);
}
.pageHeader {
  position: relative;
  margin: 20px 0;
  border-radius: 4px;
  width: 100%;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02),
    0 1px 2px rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02), 0 1px 2px rgba(0, 0, 0, 0.08);
}
.blog-header {
  position: relative;
  margin: 0 0 20px 0;
  border-radius: 4px;
  width: 100%;
  height: 300px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02),
    0 1px 2px rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02), 0 1px 2px rgba(0, 0, 0, 0.08);
  background-repeat: no-repeat;
  background-size: cover;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;

  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
}
.blog-header:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 4px;
  background-image: linear-gradient(to bottom right, #bdc3c7, #362063);
  opacity: 0.6;
}
@media only screen and (max-width: 1048px) {
  .blog-header {
    background-attachment: scroll;
  }
}
.blog-title {
  align-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
}
h1.blog-title {
  color: #fff;
  font-size: 64px;
  margin-bottom: 0;
  z-index: 2;
}
.image-gallery-swipe {
  border-radius: 4px;
}
.image-gallery-slides {
  border-radius: 4px;
}
.image-gallery-slide.center {
  border-radius: 4px;
}
.image-gallery-slide {
  border-radius: 4px;
}
.image-gallery-slide img {
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02),
    0 1px 2px rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02), 0 1px 2px rgba(0, 0, 0, 0.08);
}
.cart-price {
  margin-left: 12px;
  font-weight: 700;
}
.totalPrice {
  text-align: center;
  font-size: 28px;
  font-weight: 700;
}
.deliveryPrice {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #58595b;
}
.totalPrice span {
  color: var(--error);
}
.additionalText {
  color: var(--error);
  text-align: center;
}
.ant-spin-blur {
  clear: both;
  overflow: hidden;
  opacity: 0.1;
  -webkit-filter: blur(20px);
  filter: blur(20px);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.ant-typography + h2.ant-typography {
  margin-top: 0.2em;
  color: var(--brand-blue);
  font-weight: 700;
}
.lang-switch {
  display: flex;
}
.lang-switch button {
  margin-left: 10px;
  display: inline-flex;
}
.lang-switch img {
  border-radius: 4px;
  margin-right: 6px;
  align-self: center;
}
.active-language {
  border-color: var(--brand-blue);
  color: var(--brand-blue);
}

#products-row .ant-input-number, #products .ant-input-number {
  width: 60px;
}

.ant-card-body {
  padding: 12px 6px;
}

#products .ant-card-meta {
    margin: auto;
    position: absolute;
    bottom: 60px;
}

.ant-result {
  padding: 48px 32px;
  margin: auto;
}

.card-grid {
  width: 25%;
  text-align: center;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 24px !important;
}

.ant-card-grid {
  padding: 12px;
}
.ant-card-grid img {
  margin-right: 6px;
  width: 36px;
}

.ant-card-meta-title {
  font-size: 16px;
  font-weight: 700;
  white-space: normal;
  background: transparent;
  color: #001d3a;
}

#products .ant-card-cover {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 250px;
}
#products.results .ant-card-cover {
  height: 300px;
}
#products .ant-card-cover img {
  border-radius: 2px 2px 0 0;
  margin: auto;
  vertical-align: middle;
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
}
.ant-card-cover > * {
  display: block;
  width: 100%;
  padding: 12px;
  text-align: center;
}

.ant-card-actions {
  margin: 0;
  padding: 0;
  list-style: none;
  background: var(--cold_grey);
  border-top: 1px solid #e8e8e8;
  zoom: 1;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  align-items: center;
  display: flex;
  flex-flow: row;
}

.ant-card-actions > li > span {
  position: relative;
  display: block;
  min-width: 32px;
  font-size: 14px;
  line-height: 28px;
  cursor: pointer;
}
.ant-card-actions > li {
  float: left;
  margin: 10px auto;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
}
.ant-card-actions > li:first-child > span {
  text-align: left;
  padding-left: 4px;
}
.ant-card-actions > li:last-child > span {
  text-align: right;
  padding-right: 4px;
}
.card-price {
  font-size: 15px;
  font-weight: 700;
  color: var(--brand-blue);
  width: 100%;
  line-height: 1;
}
.card-price span {
  font-size: 10px;
}
.card-price sub {
  font-size: 9px;
}

.unit-price {
  font-size: 12px;
  font-weight: 700;
  width: 100%;
  line-height: 1;
  background: white;
  border-radius: 4px;
  border: 0.5px solid #e8e8e8;
  padding: 1.5px;
}
.unit-price span {
  font-size: 9px;
}
.unit-price sub {
  font-size: 8px;
}

#products-row .card-price {
  font-size: 14px;
  font-weight: 700;
  color: var(--brand-blue);
  width: auto;
  margin-left: 6px;
}

 .ant-list-item-meta-title {
  width: 50%;
}

#products-row .ant-list-item-meta-description {
  width: 50%;
  margin-right: 6px;
  text-align: right;
}

#product .ant-typography .card-price {
  font-size: 24px;
  font-weight: 700;
  color: var(--brand-blue);
}

#products-row .percent-price {
  display: flex;
  justify-content: flex-start;
}

.ant-list-item-meta-title > a {
  color: #000000;
}

.ant-list-item-meta-title > a:hover {
  color: var(--brand-blue);
}

.discount {
  z-index: 9;
  font-size: 10px;
  background: var(--brand-blue);
  color: #ffffff;
  display: block;
  width: 30px;
  border-radius: 12px;
  text-align: center;
  position: absolute;
  left: 14px;
  top: 6px;
}

#products-row .discount {
  z-index: 9;
  font-size: 10px;
  background: var(--brand-blue);
  color: #ffffff;
  display: none;
  float: left;
  width: 30px;
  border-radius: 30px;
  text-align: center;
  margin-left: 4px;
  position: relative;
}

.add-to-card-sub {
  font-weight: 700;
}
.categoriesName {
  margin: 6px auto;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}
.ant-tag {
  font-size: 14px;
}
.ant-card .ant-tag {
  font-size: 12px;
}
.productTags {
  font-size: 12px;
  cursor: pointer;
}
.cube {
  background: #333333;
  height: 10px;
  margin-right: 4px;
  width: 10px;
  display: inline-flex;
}
.cube.selected-cube {
  background: var(--brand-blue);
}
.ant-typography a:hover .cube {
  background: var(--brand-blue);
}
.ant-typography a {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 300;
}
.ant-typography a:hover {
  color: var(--brand-blue);
}
iframe {
  width: 100%;
  border-radius: 4px;
  height: 315px;
}
.manufacturer-logo-in-product {
  position: absolute;
  top: 6px;
  left: 12px;
  max-width: 50px;
  z-index: 9;
  opacity: 0.8;
}
#products.results .manufacturer-logo-in-product {
  max-width: 80px;
}
.manufacturer-logo-in-product {
  width: 70px;
}
.category-in-product {
  position: absolute;
  top: 6px;
  right: 12px;
  z-index: 9;
  opacity: 0.8;
}
.share_buttons {
  margin-top: 64px;
}
.share_buttons .ant-card-body {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.share_buttons svg {
  font-size: 32px;
  margin-right: 6px;
  cursor: pointer;
}
.ant-card .old-price {
  font-size: 12px;
  line-height: 0;
  text-decoration: line-through;
}

#products-row .old-price {
  font-size: 12px;
  text-decoration: line-through;
  display: none;
}

#product .old-price {
  text-decoration: line-through;
  font-size: 12px;
  font-weight: 400;
}
#product .card-price {
  font-size: 14px;
}

.socialIcons {
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
}

.socialIcons svg {
  font-size: 24px;
  margin-right: 6px;
}
.socialIcons a {
  color: var(--brand-silver);
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.socialIcons a p {
  margin: auto 0;
  font-weight: 500;
}
.ant-menu-dark .ant-menu-sub {
  background: var(--brand-blue);
}
.ant-menu-dark.ant-menu-horizontal {
  border-bottom: 0;
  width: 100%;
}
.carouselButton {
  position: absolute;
  top: 0px;
}

.fade-in {
  animation-name: fadeInOpacity;
  animation-iteration-count: unset;
  animation-timing-function: ease-in;
  animation-duration: 300ms;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.result-form {
  position: relative;
  padding: 16px;
  opacity: 1;
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  font-size: 16px;
}
.stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.center-r {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.headerSocialIcons {
  text-align: center;
}
.headerSocialIcons a {
  color: rgba(0, 0, 0, 0.65);
}
.headerSocialIcons svg {
  font-size: 24px;
  margin-right: 6px;
}
.ant-carousel .slick-dots li button {
  background: var(--brand-silver);
}
.ant-carousel .slick-dots li.slick-active button {
  background: var(--brand-blue);
}
.arrows {
  width: 100%;
  display: inline-block;
  position: absolute;
  top: 25%;
}
.prev {
  position: absolute;
  left: 0;
  z-index: 2;
  font-size: 32px;
  cursor: pointer;
  color: var(--text-color);
  opacity: 0.5;
}
.next {
  position: absolute;
  right: 0;
  z-index: 2;
  font-size: 32px;
  cursor: pointer;
  color: var(--text-color);
  opacity: 0.5;
}
.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  color: #fff;
  font-size: 5em;
  padding: 50px 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 4;
}
.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  width: 20px !important;
  font-size: 32px;
}
.alice-carousel__next-btn {
  right: 0;
}
.alice-carousel__prev-btn [data-area]::after,
.alice-carousel__next-btn [data-area]::after {
  color: #fff;
  line-height: 0.7;
  -webkit-transition: color 0.2s ease-out;
  -o-transition: color 0.2s ease-out;
  transition: color 0.2s ease-out;
}
.alice-carousel__prev-btn [data-area]:hover::after,
.alice-carousel__next-btn [data-area]:hover::after {
  color: var(--brand-silver);
}
.alice-carousel__next-btn [data-area]::after {
  content: "❯" !important;
  color: rgba(255, 255, 255, 0.3);
  line-height: 0.7;
  -webkit-transition: color 0.2s ease-out;
  -o-transition: color 0.2s ease-out;
  transition: color 0.2s ease-out;
}
.alice-carousel__prev-btn [data-area]::after {
  content: "❮" !important;
  color: rgba(255, 255, 255, 0.3);
  line-height: 0.7;
  -webkit-transition: color 0.2s ease-out;
  -o-transition: color 0.2s ease-out;
  transition: color 0.2s ease-out;
}
.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  padding: 15px 0px;
}
.ant-menu-submenu-placement-bottomLeft {
  position: absolute;
  z-index: 1091;
  background: #fff;
  border-radius: 4px;
  width: 100%;
  left: 0 !important;
  right: 0;
  margin: auto;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08),
    0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08), 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  -o-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.ant-carousel .slick-slide img {
  display: block;
  width: 100%;
  height: auto;
}
.cart {
  margin-left: 6px;
  border: none;
  color: var(--brand-red);
  background-color: var(--brand-red-background);
  height: 48px;
  width: 48px;
  font-size: 24px;
  padding: 6px 0px;
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  -o-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.cart:hover {
  color: var(--brand-red);
  background-color: var(--brand-red-background);
}
.cart:active,
.cart:focus {
  margin-left: 6px;
  border: none;
  color: var(--brand-red);
  background-color: var(--brand-red-background);
  height: 48px;
  width: 48px;
  font-size: 24px;
  padding: 6px 0px;
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  -o-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.ant-btn:focus > span,
.ant-btn:active > span {
  position: unset;
}
.cart-count {
  position: absolute;
  top: 2px;
  right: -4px;
  background-color: var(--brand-red);
  color: #ffffff;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-smooth: always;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  will-change: transform;
  font-size: 0.625rem;
  min-width: 1.0625rem;
  height: 1.0625rem;
  border-radius: 50%;
}
.ant-drawer {
  z-index: 2000;
}
.ant-table-tbody > tr > td:last-child {
  word-break: break-word;
}
.customSelect {
  position: relative;
  height: 32px;
  cursor: pointer;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-top: 1.02px solid #d9d9d9;
  border-radius: 4px;
  outline: none;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
ul li ul li .ant-select-dropdown-menu-item-group-title {
  display: none;
}
#not-found-page .ant-menu-item-selected {
  background: var(--brand-blue);
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.notFound {
  text-align: center;
}
.notFound h1 {
  font-size: 120px;
}
.mobile-header {
  display: none;
}

.result-form .ant-select,
.result-form .ant-cascader-picker {
  width: 160px !important;
}
.mobile-carousel {
  display: none;
}
.ant-input-affix-wrapper {
  border-radius: 4px;
  border: none;
}

.ant-input-affix-wrapper > input.ant-input {
  padding: 0 10px;
  border: 1px solid var(--brand-blue);
}
.ant-input-search-icon::before {
  border-left: none;
}

.ant-list-footer {
  background: var(--brand-blue);
  color: white;
  font-size: 24px;
  font-weight: 700;
  padding: 12px;
}

.headerBreak {
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  border-bottom: 1px solid #e3e4e6;
}

.alice-carousel__wrapper {
  min-height: 400px;
}

/* MOBILE */
@media only screen and (max-width: 576px) {
  .ant-layout-header {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #ffffff;
    line-height: 48px;
  }
  .logo {
    margin: auto;
  }
  .logo img {
    width: 100px;
  }
  .lang-switch {
    display: block;
    text-align: center;
  }
  .ant-carousel .slick-slide {
    text-align: center;
    height: 130px;
    line-height: 120px;
    background: #364d79;
    overflow: hidden;
  }

  .topMenu {
    display: none;
  }
  .ant-menu-dark.ant-menu-horizontal {
    width: 50px;
    background: var(--cold_grey);
    line-height: 48px;
    display: flex;
    margin: 0;
    position: absolute;
    left: 0;
  }
  .ant-menu-horizontal {
    box-shadow: none;
  }
  .ant-menu-horizontal span {
    color: var(--cold_grey);
  }
  .ant-menu-dark.ant-menu-horizontal:first-child span::before {
    content: "";
    color: var(--brand-silver);
    font-size: 24px;
  }
  .ant-menu-dark.ant-menu-horizontal:first-child .ant-menu-submenu-title {
    padding: 0 !important;
  }
  .ant-menu-dark.ant-menu-horizontal:first-child span::after {
    content: "☰";
    color: var(--brand-silver);
    font-size: 28px;
  }

  .social-name {
    display: none;
  }
  .ant-menu-vertical > .ant-menu-item,
  .ant-menu-vertical-left > .ant-menu-item,
  .ant-menu-vertical-right > .ant-menu-item,
  .ant-menu-inline > .ant-menu-item,
  .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 50px;
    line-height: 50px;
  }
  .ant-menu-dark .ant-menu-item,
  .ant-menu-dark .ant-menu-item-group-title,
  .ant-menu-dark .ant-menu-item > a {
    color: var(--brand-silver);
  }
  .ant-menu-submenu-placement-bottomLeft .ant-menu-item-selected a {
    color: white;
  }
  .mobile-header {
    display: block;
  }
  .search-filter-mobile {
    display: flex;
    align-items: center;
    background: var(--brand-blue);
    padding: 10px;
    margin: 0;
    justify-content: space-around;
  }
  .search-mobile {
    width: 60%;
  }
  .cart {
    margin-right: 10px;
    margin-top: 6px;
  }
  .ant-layout-sider {
    display: none;
  }
  .header {
    display: none;
  }
  
  .ant-form-inline .ant-form-item {
    display: flex;
    flex-direction: column;
    margin: 0;
  }
  .result-form .ant-select,
  .result-form .ant-cascader-picker {
    width: 250px !important;
  }
  .prodResForm {
    display: none;
  }
  .lang-switch {
    padding: 10px 15px 10px;
  }
  .mobile-carousel {
    display: block;
  }
  .ant-carousel .slick-list {
    border-radius: 0;
  }
  .socialIcons {
    display: flex;
    justify-content: center;
  }
  .footer-social {
    display: flex;
    justify-content: center;
  }
  .find-us {
    flex-direction: column;
  }
  .footer-phones {
    padding-right: 0;
    text-align: center;
  }
  .pages-col {
    padding-bottom: 0 !important;
  }
  .find-us-col {
    padding-top: 0 !important;
  }
  .ant-modal-body > .ant-row > .ant-card {
    box-shadow: none;
  }
  .ant-modal-body > .ant-row > .ant-card-content:hover {
    box-shadow: none;
  }
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-content {
    border-radius: 4px;
  }
  
  .card-grid {
    width: 50%;
    text-align: center;
    font-size: 12px;
  }

  .alice-carousel__wrapper {
    min-height: 0;
  }
}
@media only screen and  (min-width: 576px) and (max-width: 768px) {

  .logo img {
    width: 220px;
  }

  .headerCartBreak {
    text-align: right;
    padding-right: 10px;
    position: absolute;
    right: 0;
    top: -50px;
    z-index: 1000;
  }

  .card-grid {
    width: 50%;
    text-align: center;
    font-size: 1rem;
  }
  .alice-carousel__wrapper {
    min-height: 0;
  }
}
#products .ant-skeleton {
  display: flex;
  flex-direction: column;
}

#products .ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  width: 100%;
  height: 200px;
  border-radius: 4px;
}
.ant-skeleton-header {
  padding-right: 0;
}

#products .ant-avatar-image {
  height: 200px;
  width: 100%;
  border-radius: 4px;
}

#products .ant-avatar > img {
  object-fit: contain;
}

#products .ant-card-meta-avatar {
  display: inline-flex;
  padding-right: 0;
  height: 200px;
}

.products-list .ant-list-item-meta-content {
  display: inline-flex;
  justify-content: space-between;
}

.product-list-row {
  font-size: 16px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0;
}
.sign-in-modal,
.sign-in-modal .ant-modal-content,
.sign-in-modal .ant-modal-header {
  border-radius: 4px;
}
.sign-in-modal .ant-modal-footer {
  display: none;
}

.login-form {
  max-width: 300px;
  margin: auto;
}
.login-form-forgot {
  float: right;
}
.ant-col-rtl .login-form-forgot {
  float: left;
}
.login-form-button {
  width: 100%;
}
.user-login-icon {
  color: var(--brand-blue);
  line-height: 42px;
  margin-left: 12px;
  background-color: var(--brand-blue-background);
  padding: 0;
  border-radius: 50%;
  vertical-align: 0;
  width: 32px;
  height: 32px;
}
.user-login-icon svg {
  font-size: 24px;
}
.user-login-icon:hover svg {
  fill: var(--brand-violet2);
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px;
}
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding-left: 6px;
}
.account-card .ant-card-body {
  padding: 16px 16px;
}
.customer-details {
  margin-top: 16px;
  list-style: none;
}

/*
 * CKEditor 5 (v24.0.0) content styles.
 * Generated on Thu, 10 Dec 2020 08:15:26 GMT.
 * For more information, check out https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/content-styles.html
 */

:root {
  --ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
  --ck-color-mention-text: hsl(341, 100%, 30%);
  --ck-highlight-marker-blue: hsl(201, 97%, 72%);
  --ck-highlight-marker-green: hsl(120, 93%, 68%);
  --ck-highlight-marker-pink: hsl(345, 96%, 73%);
  --ck-highlight-marker-yellow: hsl(60, 97%, 73%);
  --ck-highlight-pen-green: hsl(112, 100%, 27%);
  --ck-highlight-pen-red: hsl(0, 85%, 49%);
  --ck-image-style-spacing: 1.5em;
  --ck-todo-list-checkmark-size: 16px;
}

/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized {
  max-width: 100%;
  display: block;
  box-sizing: border-box;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized img {
  width: 100%;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized > figcaption {
  display: block;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-side {
  float: right;
  margin-left: var(--ck-image-style-spacing);
  max-width: 50%;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left {
  float: left;
  margin-right: var(--ck-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-center {
  margin-left: auto;
  margin-right: auto;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-right {
  float: right;
  margin-left: var(--ck-image-style-spacing);
}
/* ckeditor5-image/theme/image.css */
.ck-content .image {
  display: table;
  clear: both;
  text-align: center;
  margin: 1em auto;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  min-width: 50px;
}
/* ckeditor5-image/theme/imagecaption.css */
.ck-content .image > figcaption {
  display: table-caption;
  caption-side: bottom;
  word-break: break-word;
  color: hsl(0, 0%, 20%);
  background-color: hsl(0, 0%, 97%);
  padding: 0.6em;
  font-size: 0.75em;
  outline-offset: -1px;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-yellow {
  background-color: var(--ck-highlight-marker-yellow);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-green {
  background-color: var(--ck-highlight-marker-green);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-pink {
  background-color: var(--ck-highlight-marker-pink);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-blue {
  background-color: var(--ck-highlight-marker-blue);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-red {
  color: var(--ck-highlight-pen-red);
  background-color: transparent;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-green {
  color: var(--ck-highlight-pen-green);
  background-color: transparent;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-tiny {
  font-size: 0.7em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-small {
  font-size: 0.85em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-big {
  font-size: 1.4em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-huge {
  font-size: 1.8em;
}
/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content blockquote {
  overflow: hidden;
  padding-right: 1.5em;
  padding-left: 1.5em;
  margin-left: 0;
  margin-right: 0;
  font-style: italic;
  border-left: solid 5px hsl(0, 0%, 80%);
}
/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content[dir="rtl"] blockquote {
  border-left: 0;
  border-right: solid 5px hsl(0, 0%, 80%);
}
/* ckeditor5-basic-styles/theme/code.css */
.ck-content code {
  background-color: hsla(0, 0%, 78%, 0.3);
  padding: 0.15em;
  border-radius: 2px;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table {
  margin: 1em auto;
  display: table;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  border: 1px double hsl(0, 0%, 70%);
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table td,
.ck-content .table table th {
  min-width: 2em;
  padding: 0.4em;
  border: 1px solid hsl(0, 0%, 75%);
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table th {
  font-weight: bold;
  background: hsla(0, 0%, 0%, 5%);
}
/* ckeditor5-table/theme/table.css */
.ck-content[dir="rtl"] .table th {
  text-align: right;
}
/* ckeditor5-table/theme/table.css */
.ck-content[dir="ltr"] .table th {
  text-align: left;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break {
  position: relative;
  clear: both;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break::after {
  content: "";
  position: absolute;
  border-bottom: 2px dashed hsl(0, 0%, 77%);
  width: 100%;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break__label {
  position: relative;
  z-index: 1;
  padding: 0.3em 0.6em;
  display: block;
  text-transform: uppercase;
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
  font-size: 0.75em;
  font-weight: bold;
  color: hsl(0, 0%, 20%);
  background: hsl(0, 0%, 100%);
  box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* ckeditor5-media-embed/theme/mediaembed.css */
.ck-content .media {
  clear: both;
  margin: 1em 0;
  display: block;
  min-width: 15em;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list {
  list-style: none;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li {
  margin-bottom: 5px;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li .todo-list {
  margin-top: 5px;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input {
  -webkit-appearance: none;
  display: inline-block;
  position: relative;
  width: var(--ck-todo-list-checkmark-size);
  height: var(--ck-todo-list-checkmark-size);
  vertical-align: middle;
  border: 0;
  left: -25px;
  margin-right: -15px;
  right: 0;
  margin-left: 0;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::before {
  display: block;
  position: absolute;
  box-sizing: border-box;
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid hsl(0, 0%, 20%);
  border-radius: 2px;
  transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background,
    250ms ease-in-out border;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::after {
  display: block;
  position: absolute;
  box-sizing: content-box;
  pointer-events: none;
  content: "";
  left: calc(var(--ck-todo-list-checkmark-size) / 3);
  top: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  width: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  height: calc(var(--ck-todo-list-checkmark-size) / 2.6);
  border-style: solid;
  border-color: transparent;
  border-width: 0 calc(var(--ck-todo-list-checkmark-size) / 8)
    calc(var(--ck-todo-list-checkmark-size) / 8) 0;
  transform: rotate(45deg);
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::before {
  background: hsl(126, 64%, 41%);
  border-color: hsl(126, 64%, 41%);
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::after {
  border-color: hsl(0, 0%, 100%);
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label .todo-list__label__description {
  vertical-align: middle;
}
/* ckeditor5-html-embed/theme/htmlembed.css */
.ck-content .raw-html-embed {
  margin: 1em auto;
  min-width: 15em;
  font-style: normal;
}
/* ckeditor5-horizontal-line/theme/horizontalline.css */
.ck-content hr {
  margin: 15px 0;
  height: 4px;
  background: hsl(0, 0%, 87%);
  border: 0;
}
/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre {
  padding: 1em;
  color: hsl(0, 0%, 20.8%);
  background: hsla(0, 0%, 78%, 0.3);
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  text-align: left;
  direction: ltr;
  tab-size: 4;
  white-space: pre-wrap;
  font-style: normal;
  min-width: 200px;
}
/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre code {
  background: unset;
  padding: 0;
  border-radius: 0;
}
/* ckeditor5-mention/theme/mention.css */
.ck-content .mention {
  background: var(--ck-color-mention-background);
  color: var(--ck-color-mention-text);
}
@media print {
  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break {
    padding: 0;
  }
  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break::after {
    display: none;
  }
}
